exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-alle-produkte-js": () => import("./../../../src/pages/alle-produkte.js" /* webpackChunkName: "component---src-pages-alle-produkte-js" */),
  "component---src-pages-applogin-js": () => import("./../../../src/pages/applogin.js" /* webpackChunkName: "component---src-pages-applogin-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-app-js": () => import("./../../../src/pages/datenschutz-app.js" /* webpackChunkName: "component---src-pages-datenschutz-app-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-digital-teacher-planner-js": () => import("./../../../src/pages/digital-teacher-planner.js" /* webpackChunkName: "component---src-pages-digital-teacher-planner-js" */),
  "component---src-pages-digitaler-kalender-js": () => import("./../../../src/pages/digitaler-kalender.js" /* webpackChunkName: "component---src-pages-digitaler-kalender-js" */),
  "component---src-pages-digitaler-lehrerkalender-js": () => import("./../../../src/pages/digitaler-lehrerkalender.js" /* webpackChunkName: "component---src-pages-digitaler-lehrerkalender-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lehrerkalender-goodnotes-js": () => import("./../../../src/pages/lehrerkalender-goodnotes.js" /* webpackChunkName: "component---src-pages-lehrerkalender-goodnotes-js" */),
  "component---src-pages-liavo-lehrerkalender-app-js": () => import("./../../../src/pages/liavo-lehrerkalender-app.js" /* webpackChunkName: "component---src-pages-liavo-lehrerkalender-app-js" */),
  "component---src-pages-liavo-planer-app-js": () => import("./../../../src/pages/liavo-planer-app.js" /* webpackChunkName: "component---src-pages-liavo-planer-app-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rabatte-js": () => import("./../../../src/pages/rabatte.js" /* webpackChunkName: "component---src-pages-rabatte-js" */),
  "component---src-pages-support-erste-schritte-js": () => import("./../../../src/pages/support/erste-schritte.js" /* webpackChunkName: "component---src-pages-support-erste-schritte-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-templates-product-details-js": () => import("./../../../src/templates/product_details.js" /* webpackChunkName: "component---src-templates-product-details-js" */)
}

